import create from 'zustand';

export type LoginDrawerMode =
    | 'login'
    | 'signup'
    | 'forgotpassword'
    | 'forgotpasswordsubmitted'
    | 'forgotpasswordsetnew'
    | 'forgotpassworderror'
    | 'changepassword'
    | 'changepasswordfail'
    | 'changepassworddone';

type UseLoginDrawer = {
    // The current open state
    isLoginDrawerOpen: boolean;
    loginDrawerMode: LoginDrawerMode;

    // Specify the open state
    setIsLoginDrawerOpen: (isOpen: boolean) => void;
    setLoginDrawerMode: (mode: LoginDrawerMode) => void;
};

/**
 * Global and shared store for the login drawer.
 * Avoid using directly - use the `useLoginDrawer` hook instead
 */
export const useLoginDrawerState = create<UseLoginDrawer>((set) => ({
    isLoginDrawerOpen: false,
    loginDrawerMode: 'login',
    setIsLoginDrawerOpen: (isLoginDrawerOpen) => set({ isLoginDrawerOpen }),
    setLoginDrawerMode: (loginDrawerMode) => set({ loginDrawerMode }),
}));
