import { useScrollLock } from '~/shared/hooks/useScrollLock';
import { LoginDrawerMode, useLoginDrawerState } from './useLoginDrawerState';

type TLoginDrawer = {
    scrollLock?: boolean;
    setLoginDrawerMode: () => void;
};

const defaultOptions: TLoginDrawer = {
    scrollLock: true,
    setLoginDrawerMode: () => 'login',
};
/**
 * Use to open and close the login drawer
 * It ensures a scroll lock when open.
 */
export const useLoginDrawer = (options?: TLoginDrawer) => {
    const { scrollLock } = { ...defaultOptions, ...options };

    const {
        isLoginDrawerOpen,
        setIsLoginDrawerOpen,
        loginDrawerMode,
        setLoginDrawerMode,
    } = useLoginDrawerState();

    const { lock, unlock } = useScrollLock();

    const open = (mode: LoginDrawerMode) => {
        setLoginDrawerMode(mode);
        setIsLoginDrawerOpen(true);
        if (scrollLock) {
            lock();
        }
    };

    const close = () => {
        setIsLoginDrawerOpen(false);
        if (scrollLock) {
            unlock();
        }
    };

    const toggle = (mode: LoginDrawerMode = 'login') => {
        isLoginDrawerOpen ? close() : open(mode);
    };

    return {
        isLoginDrawerOpen,
        setIsLoginDrawerOpen,
        loginDrawerMode,
        setLoginDrawerMode,
        open,
        close,
        toggle,
    };
};
