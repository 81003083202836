import Logo from '$icons/logo.svg';
import { Container, Link, StyledText, Button } from '$shared/components';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledInput } from '~/shared/components/form/components/controllable-elements/input-element/styled';
import { theme } from '~/theme';
import { mq } from '~/lib';
import { motion } from 'framer-motion';
import { breakpoints } from '~/theme/breakpoints';

type StyledIconButtonProps = {
    mobileOnly?: boolean;
    onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>;
    full?: boolean;
    desktop?: boolean;
    textColor?: string;
    reverseIcon?: boolean;
    checkout?: boolean;
    mobile?: boolean;
};

export const StyledIconButton = styled(Link)<StyledIconButtonProps>(
    ({ theme }) => ({
        display: 'flex',
        padding: `${theme.space[4]} ${theme.space[2]}`,
        position: 'relative',
        '&:hover': {
            backgroundColor: theme.colors.grey30,
            color: 'inherit',
            textDecoration: 'inherit',
        },
        svg: {
            height: 24,
            width: 24,
        },
        alignItems: 'center',
    }),
    ifProp('mobileOnly', {
        svg: {
            height: 16,
            width: 16,
        },
        [breakpoints.sm]: {
            display: 'none',
        },
    }),
);

export const StyledSmallIconButton = styled(StyledIconButton)<StyledIconButtonProps>(
    ({ theme }) => ({
        flexDirection: 'column',
        margin: `unset`,
        padding: `${theme.space[1]} ${theme.space[1]} ${theme.space[1]} ${theme.space[1]}`,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        svg: {
            width: `${theme.metrics.xsmall}`,
            height: `${theme.metrics.xsmall}`,
            margin: 'unset',
        },
        [breakpoints.xs]: {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 'unset',
            svg: {
                height: 16,
                width: 16,
            },
        },
    }),
    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
    })),
);
export const StyledSmallIconButtonUsp = styled.div<StyledIconButtonProps>(
    ({ theme }) => ({
        padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${theme.space[2]}`,
        margin: `${theme.space[1]}`,
        flexDirection: 'row',
        display: 'flex',
        '&:hover': {
            backgroundColor: 'unset',
            textDecoration: 'none',
        },
        svg: {
            width: 24,
            height: 24,
        },
    }),
    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
        svg: {
            '& path': {
                fill: textColor,
            },
        },
    })),
);

export const StyledIconButtonLogin = styled(StyledSmallIconButton)<StyledIconButtonProps>(
    () => ({
        [breakpoints.xs]: {
            display: 'flex',
        },
        [breakpoints.sm]: {
            display: 'flex',
        },
        [breakpoints.md]: {
            display: 'inherit',
        },
    }),
    ifProp('checkout', () => ({
        flexDirection: 'row',
        position: 'absolute',
        top: `-${theme.space[4]}`, // Breadcrumbs padding
        right: 0,
        transform: 'translateY(-100%)',
        padding: 0,

        [mq(0, 'md')]: {
            display: 'none',
        },
    })),
);

export const StyledIconBadge = styled(motion.span)(({ theme }) => ({
    ...theme.mixins.useTextStyle('caption'),
    borderRadius: '50%',
    position: 'absolute',
    height: '20px',
    width: '20px',
    top: '-6px',
    right: '-2px',
    backgroundColor: theme.colors.brandOrangeMedium,
    color: theme.colors.white,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
}));

export const StyledContent = styled(Container)<{
    topBanner?: boolean;
    desktop?: boolean;
}>(
    () => ({
        display: 'flex',
    }),
    ifProp('topBanner', ({ theme }) => ({
        padding: 0,
        maxWidth: 'unset',
        [breakpoints.xs]: {
            marginTop: theme.space[2],
            padding: `${theme.space[2]} 0 0 0`,
        },
    })),
);

export const StyledContainerMotion = styled(Container)<{
    isHiddenMobile: boolean;
    isMobile?: boolean;
}>(
    () => ({
        opacity: 1,
        maxWidth: '100%',
        transition: `transform 1s ease, opacity 1s ease`,
    }),
    ifProp('isHiddenMobile', () => ({
        opacity: 0,
        transform: 'translateY(-100%)',
    })),
);

export const StyledBannerMotion = styled(Container)<{
    isHidden: boolean;
}>(
    () => ({
        position: 'sticky',
        opacity: 1,
        maxWidth: '100%',
        overflow: 'hidden',
        transition: `opacity 0.5s ease,`,
    }),
    ifProp('isHidden', () => ({
        opacity: 0,
        display: 'none',
    })),
);

export const StyledMainNavigation = styled(motion.header)(({ theme }) => ({
    top: 0,
    transition: '1s',
    position: 'sticky',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    boxShadow: `0 2px 20px 0 rgba(0,0,0,0.2)`,
    backgroundColor: theme.colors.headerColor,
    zIndex: theme.zIndices[2],
    [breakpoints.xs]: {
        position: 'sticky',
        padding: 0,
    },
}));

export const StyledContainerMotionFilter = styled(Container)<{
    isDisplayed: boolean;
}>(
    () => ({
        position: 'absolute',
        opacity: 0,
        transition: `transform 1s ease, opacity 0.5s ease`,
        transform: 'translateY(-100%)',
    }),
    ifProp('isDisplayed', () => ({
        zIndex: 1000,
        backgroundColor: theme.colors.headerColor,
        opacity: 1,
        height: '200px',
        maxWidth: '100%',
        transform: `translateY(33px)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    })),
);

export const MenuHeaderContainer = styled(StyledContent)(({ theme }) => ({
    position: 'relative',
    padding: `${theme.space[4]} ${theme.space[5]} ${theme.space[2]} ${theme.space[5]}`,
    justifyContent: 'space-between',
    maxWidth: '1480px',

    [mq(0, 'md')]: {
        padding: `${theme.space[2]} ${theme.space[3]}`,
    },
}));

export const StyledNav = styled.nav({
    display: 'flex',
});

export const StyledDesktopNav = styled(Container)(({ theme }) => ({
    display: 'none',
    padding: `${theme.space[3]} ${theme.space[6]} 0px ${theme.space[6]}`,
    width: 'unset',
    [breakpoints.md]: {
        display: 'inherit',
    },
}));

export const StyledIconNav = styled(StyledNav)<{
    notMobile?: boolean;
    mobile?: boolean;
    justifyContent?: string;
}>(
    () => ({
        display: 'flex',
        flexGrow: 1,
        flexBasis: 0,
        alignContent: 'center',
        alignItems: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
    }),
    ifProp('mobile', () => ({
        display: 'none',
        [breakpoints.sm]: {
            display: 'flex',
            alignItems: 'center',
        },
        [breakpoints.xs]: {
            display: 'flex',
            alignItems: 'center',
            svg: {
                height: 16,
                width: 16,
            },
        },
        [breakpoints.md]: {
            display: 'none',
        },
    })),
    ifProp('justifyContent', ({ justifyContent }) => ({
        justifyContent: justifyContent,
        gap: theme.space[1],
        [breakpoints.md]: {
            flexDirection: 'row',
        },
    })),
    ifProp('notMobile', () => ({
        display: 'none',
        [breakpoints.md]: {
            display: 'flex',
        },
    })),
);

export const StyledLeftContainer = styled.div({
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    zIndex: 200,
});

export const StyledRightContainer = styled.div({
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    zIndex: 200,
});

export const StyledLogoContainer = styled.div<{ activeOverlay: boolean }>(
    () => ({
        display: 'flex',
        flexGrow: 0,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',

        [breakpoints.xs]: {
            padding: `0 ${theme.space[1]} ${theme.space[1]} ${theme.space[1]}`,
        },
        [breakpoints.md]: {
            padding: 0,
        },
    }),
    ifProp('activeOverlay', () => ({
        [breakpoints.sm]: {
            display: 'none',
        },
    })),
);

export const StyledLogo = styled(Logo)(({ theme }) => ({
    display: 'flex',
    height: theme.space[5],
    width: 253,
    [mq(0, 'xs')]: {
        height: 11,
        width: 75,
    },
    [mq('xs', 'md')]: {
        height: 22,
        width: 150,
    },
}));
export const StyledHeaderOptionText = styled(StyledText)(({ theme }) => ({
    color: theme.colors.headerTextColor,
    ...theme.mixins.useTextStyle('bodySmall'),
    [breakpoints.xs]: {
        fontSize: 11,
    },
}));

export const StyledHeaderSmallIconOptionText = styled(StyledHeaderOptionText)<{
    desktop?: boolean;
    checkout?: boolean;
    textColor?: string;
    metaMenu?: boolean;
}>(
    () => ({
        fontSize: theme.fontSizes['2xs'],
        [breakpoints.xs]: {
            display: 'flex',
        },
        '&:hover': {
            textDecoration: 'none',
        },
    }),
    ifProp('desktop', ({ theme }) => ({
        marginLeft: theme.space[3],
    })),
    ifProp('checkout', ({ theme }) => ({
        marginLeft: theme.space[2],
        fontSize: theme.fontSizes['xs'],
        fontWeight: theme.fontWeights.medium,
    })),
    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
    })),
    ifProp('metaMenu', () => ({
        marginLeft: 0,
    })),
);
export const StyledHeaderSmallIconOptionTextUsp = styled(StyledHeaderOptionText)<{
    desktop?: boolean;
    textColor?: string;
    metaMenu?: boolean;
}>(
    () => ({
        marginLeft: theme.space[3],
        [breakpoints.xs]: {
            display: 'flex',
        },
        '&:hover': {
            textDecoration: 'none',
        },
    }),

    ifProp('textColor', ({ textColor }) => ({
        color: textColor,
    })),
);

export const StyledSearchInputMobile = styled(StyledInput)(({ theme }) => ({
    color: theme.colors.headerTextColor,
    textAlign: 'center',
    width: 'unset',
    padding: `0 0 0 49px`,
    backgroundColor: theme.colors.headerColor,
    '::placeholder': {
        textAlign: 'left',
    },
    height: 40,
}));

export const StyledSearchInputContainer = styled(Container)<{
    notMobile?: boolean;
    activeOverlay?: boolean;
}>(
    ({ theme }) => ({
        position: 'relative',
        display: 'none',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: 'unset',
        opacity: 1,
        justifyContent: 'center',
        border: `1px solid ${theme.colors.headerSearchInputBorderColor}`, // move to theme the color
        borderRadius: theme.space[1],
        backgroundColor: theme.colors.headerColor,
        margin: `${theme.space[2]} ${theme.space[3]} ${theme.space[2]} ${theme.space[3]}`,
        height: '42px',
        zIndex: 200,

        [breakpoints.sm]: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            width: 'unset',
            opacity: 1,
            justifyContent: 'center',
            border: `1px solid ${theme.colors.headerSearchInputBorderColor}`, // move to theme the color
            borderRadius: theme.space[1],
            backgroundColor: theme.colors.headerColor,

            height: '56px',
        },
    }),
    ifProp('notMobile', () => ({
        [breakpoints.sm]: {
            // display: 'flex',
            flexGrow: 1,
            flexBasis: 0,
            margin: `0`,
            maxWidth: `calc(min(25vw, ${theme.sizes.pageMaxWidth / 4}px) - ${theme.space[6]})`,
        },
    })),
    ifProp('activeOverlay', () => ({
        [breakpoints.sm]: {
            maxWidth: '690px',
            transition: 'max-width 0.5s ease-in-out',
        },
    })),
);

export const StyledSearchInputContainerMobile = styled(StyledSearchInputContainer)<{
    notMobile?: boolean;
    activeOverlay?: boolean;
}>(
    () => ({
        display: 'flex',
        margin: `${theme.space[3]}`,
        [breakpoints.md]: {
            display: 'none',
        },
    }),
    ifProp('notMobile', () => ({
        [breakpoints.sm]: {
            display: 'flex',
            flexGrow: 1,
            flexBasis: 0,
            margin: `0`,
            maxWidth: `calc(min(25vw, ${theme.sizes.pageMaxWidth / 4}px) - ${theme.space[6]})`,
        },
        [breakpoints.xs]: {
            display: 'none',
        },
    })),
    ifProp('activeOverlay', () => ({
        [breakpoints.sm]: {
            maxWidth: '690px',
            transition: 'max-width 0.5s ease-in-out',
        },
    })),
);

export const StyledSearchInputDesktop = styled(StyledSearchInputMobile)(() => ({
    textAlign: 'left',
    width: '100%',
    '::placeholder': {
        textAlign: 'center',
    },
}));

export const StyledSearchCloseButton = styled(Button)(({ theme }) => ({
    paddingLeft: theme.space[4],
    paddingRight: theme.space[4],
    svg: {
        height: '11px',
        width: '11px',
    },
}));

export const SearchPlaceholder = styled.div(({ theme }) => ({
    position: 'absolute',
    color: theme.colors.black,
    ...theme.mixins.useTextStyle('bodySmall'),
    display: 'flex',
    alignItems: 'center',
    gap: theme.space[2],
    left: `${theme.space[3]}`,
    svg: {
        height: 24,
        width: 24,
    },
}));

export const ProgressLoader = styled.div({
    position: 'relative',
    height: '2px',
    marginTop: '-2px',
    width: '100%',
    zIndex: 0,
});

export const SearchPlaceholderIconContainer = styled.div({
    zIndex: 1,
    display: 'flex',
    cursor: 'pointer',
});

export const SearchPlaceholderTextContainer = styled.div({
    pointerEvents: 'none',
    zIndex: 1,
    display: 'flex',
});
